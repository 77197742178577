import {
  Box,
  Stack,
} from "@mui/material";
import { IncidentTypeSelection } from "./IncidentType/IncidentTypeSelection";
import { LocationSelection } from "./Location/LocationSelection";
import { IncidentTime } from "./IncidentTime/IncidentTime";
import { DepartmentSelection } from "./Department/DepartmentSelection";
import React from "react";
import { useMyProfileQuery } from "../../../../../services/profileService/profile-api";
import { useAppSelector } from "../../../../../app/hooks";

function VarianceHeader() {
  const incident = useAppSelector(state  => state.varianceRequest.varianceRequest);
  const { data } = useMyProfileQuery();
  const canEdit =():boolean=>{
    if(incident.status === "Complete"){return false;}
    if(data?.role === 'Admin'){return true;}
    if(data?.role === 'Quality Team'){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentQualityTeam') != undefined){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentAdmin') != undefined){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentEdit' && incident.status === "Started") != undefined){return true;}
    if(data?.upn === incident?.assignedTo?.upn && incident.status === "Assigned"){return true;}
    return false;
}

  return (
    <React.Fragment>
      <Box mt={2} mb={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          {/* <LocationSelection canEdit={canEdit()}/> */}
          <DepartmentSelection canEdit={canEdit()}/>
          <IncidentTypeSelection canEdit={canEdit()}/>
          <IncidentTime canEdit={canEdit()}/>
        </Stack>
      </Box>
    </React.Fragment>
  );
}

export { VarianceHeader };
